import { render, staticRenderFns } from "./ViewTrialEnd.vue?vue&type=template&id=b7d85038&scoped=true&"
import script from "./ViewTrialEnd.vue?vue&type=script&lang=ts&"
export * from "./ViewTrialEnd.vue?vue&type=script&lang=ts&"
import style0 from "./ViewTrialEnd.vue?vue&type=style&index=0&id=b7d85038&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7d85038",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b7d85038')) {
      api.createRecord('b7d85038', component.options)
    } else {
      api.reload('b7d85038', component.options)
    }
    module.hot.accept("./ViewTrialEnd.vue?vue&type=template&id=b7d85038&scoped=true&", function () {
      api.rerender('b7d85038', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ViewTrialEnd.vue"
export default component.exports