var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c("div", { staticClass: "flex flex-wrap" }, [
        _c("div", { staticClass: "limiter scrollbar" }, [
          _c(
            "div",
            { staticClass: "flex flex-column justify-center items-center mt6" },
            [
              _c("p", { staticClass: "f3 tc light-gray fw2" }, [
                _vm._v(
                  "\n          Your FREE trial has expired, contact\n          "
                ),
                _c("span", { staticClass: "fw5" }, [
                  _vm._v("hello@adorilabs.com"),
                ]),
                _vm._v("\n          to keep enjoying the\n          "),
                _c("span", { staticClass: "fw5" }, [
                  _vm._v(" Adori Experience!"),
                ]),
              ]),
              _c("img", {
                attrs: { src: require("@/assets/trial-end.svg"), alt: "" },
              }),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }